
import { mapState, mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
export default {
    mixins: [cms],
    data() {
        return {
            auth: this.$cookie.get('token'),
            loginModal: false,
            registerModal: false,
        }
    },
    computed: {
        ...mapState({
            player: (state) => state.player.player,
            isLogin: (state) => state.settings.isLogin,
        }),
        ...mapGetters({
            languages: 'settings/languages',
            headerLogo: 'cms/headerLogo',
        }),
    },
    mounted() {
        if (this.$cookie.get('token')) {
            if (this.player.player_id) return
            this.$store.dispatch('player/profile')
            this.$store.dispatch('settings/toggleIsLogin', true)
            this.$store.dispatch('player/totalBalance')
        }
    },
    methods: {
        getCurrentLocale(localeCode) {
            const currentLocaleData = this.languages.filter(
                (el) => el.code === localeCode
            )
            if (currentLocaleData.length > 0) {
                return currentLocaleData[0]?.flag_icon
            }
            return null
        },
        openSignInModal() {
            this.registerModal = false
            this.loginModal = true
        },
        openSignUpModal() {
            this.loginModal = false
            this.registerModal = true
        },
    },
}
